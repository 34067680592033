@font-face {
    font-family: "SUIT-Regular";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

:root {
    --black: "#2a2f37";
    --gray3: "#828282";
}
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: white;
}

*::-webkit-scrollbar-thumb {
    background-color: #3538bb;
    border: 1px solid transparent;
    border-radius: 50px;
    background-clip: content-box;
}

@keyframes vibration {
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(5px);
    }
    50% {
        transform: translateY(-5px);
    }
    75% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}
/* body { */
/*     touch-action: none; */
/* } */
